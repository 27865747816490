:root {
  --dark: #2a2d34; /* Modern charcoal gray for text and dark elements */
  --dark-translucent: rgba(42, 45, 52, 0.8); /* Slightly transparent dark gray */
  
  --light-highlight: #4A90E2; /* Elegant sky blue for highlights */
  --light-highlight-translucent: rgba(74, 144, 226, 0.5); /* Translucent light blue */
  
  --dark-highlight: #1F7A8C; /* Sophisticated teal blue for accents */
  --dark-highlight-translucent: rgba(31, 122, 140, 0.5); /* Subtle translucent teal */
  
  --light-one: #F8F9FA; /* Clean, crisp off-white background */
  --light-two: #EDEDED; /* Light gray for secondary backgrounds */
  
  --white: #ffffff; /* Pure white for contrast and content areas */
  
  --dark-accent: #6D7F8A; /* Refined slate gray-blue for subdued accents */
  --light-accent: #B2E4DB; /* Calming mint green for soft highlights */
  
  --error-background: rgba(255, 99, 71, 0.15); /* Gentle translucent tomato red for error backgrounds */
  --error-color: #FF6347; /* Vibrant tomato red for error text */
  
  --pin-color: #FF6F61; /* Energetic coral pink for attention-grabbing elements */
}

@font-face {
    font-family: Euclid Circular A;
    src: url("./fonts/EuclidCircularA-Regular-WebXL.woff2") format('woff2');
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: Euclid Circular A;
    src: url("./fonts/EuclidCircularA-Medium-WebXL.woff2") format('woff2');
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: Brand Value Serif;
    src: url("./fonts/MongoDBValueSerif-Regular.woff2") format('woff2');
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: Brand Value Serif;
    src: url("./fonts/MongoDBValueSerif-Medium.woff2") format('woff2');
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: Brand Value Serif;
    src: url("./fonts/MongoDBValueSerif-Bold.woff2") format('woff2');
    font-weight: bold;
    font-display: swap;
  }
  
  @font-face {
    font-family: Source Code Pro;
    src: url("./fonts/SourceCodePro-Regular.ttf") format('truetype');
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: Source Code Pro;
    src: url("./fonts/SourceCodePro-Medium.ttf") format('truetype');
    font-weight: 500;
    font-display: swap;
  }