@import url('brand.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

/* @import url('index.css'); */

body {
  margin: 0;
}

.topnav {
  overflow: hidden;
  background-color: var(--dark-translucent);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.topnav a {
  float: left;
  display: block;
  color: var(--light-one);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: var(--light-one);
  color: var(--dark);
}

.topnav a.active {
  background-color: var(--dark-highlight-translucent);
  color: var(--light-one);
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 950px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 950px) {
  /* .topnav.responsive {position: relative;} */
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
